import React from 'react'
import Seo from '@components/Seo'
import {LANG} from '@constants/theme'
import {IndexTemplate} from '@pages/index'
import '@fontsource/noto-sans-kr'
import '../css/index.css'

export default function IndexPage() {
  return (
    <>
      <Seo
        title={'SumOne ㅡ 1000만 번 사랑에 빠진 앱'}
        description={'연인과 함께 쓰는 커플 다이어리'}
        lang={LANG.ko}
      />
      <IndexTemplate lang={LANG.ko} />
    </>
  )
}
